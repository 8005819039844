// Polyfills
import './polyfills/matches';

// Plugins
// import './plugins/pushMenu';
// import './plugins/infiniteScroll';
import './plugins/imageZoom';
import './plugins/slider';
import './plugins/tabs';
import './plugins/productSwitcher';

// Pages
import global from './global';
import index from './index';
import category from './category';
import product from './product';
import cart from './cart';
import checkout from './checkout';

const pages = { index, category, product, cart, checkout };

document.addEventListener('DOMContentLoaded', () => {
  global.init();

  if (pages[prestashop.page.page_name]) {
    pages[prestashop.page.page_name].init();
  }
});
