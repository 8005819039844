(window => {
    function ImageZoom(options) {
        this.options = JSON.parse(JSON.stringify(this.defaults));

        if (typeof options === 'object') {
            Object.values(options).map((option, i) => {
                this.options[Object.keys(options)[i]] = option;
                return true;
            });
        }

        this._init();
    }

    ImageZoom.prototype = {
        defaults: {
            target: '.product-cover__image',
            container: '.product-cover',
            imageType: {
                large: 'product_zoom',
                normal: 'large_default'
            }
        },
        _init() {
            document.addEventListener('mousemove', e => this._parentEquals(e, this.options.container) ? this._hover(e) : this._leave());
        },
        _hover(e) {
            const imgWrapper = document.querySelector(this.options.container);
            const img = document.querySelector(this.options.target);
            const imgUrl = img.attributes.src.value.replace(this.options.imageType.normal, this.options.imageType.large);

            const posX = this._getPosition('X', e, imgWrapper);
            const posY = this._getPosition('Y', e, imgWrapper);

            if (imgWrapper.style.backgroundImage !== imgUrl) {
                imgWrapper.style.backgroundImage = `url(${imgUrl})`;
                img.style.opacity = 0;
            }

            imgWrapper.style.backgroundPositionX = `${posX * 100}%`;
            imgWrapper.style.backgroundPositionY = `${posY * 100}%`;
        },
        _leave() {
            document.querySelector(this.options.container).style.backgroundImage = null;
            document.querySelector(this.options.target).style.opacity = 1;
        },
        _getPosition(type, target, container) {
            switch (type) {
                case 'X':
                    return target.offsetX ? target.offsetX / container.offsetWidth : target.pageX - container.offsetLeft / container.offsetWidth;
                default:
                    return target.offsetY ? target.offsetY / container.offsetHeight : target.pageY - container.offsetTop / container.offsetHeight;
            }
        },
        _parentEquals(e, parent) {
            if (e.target.parentNode.matches(parent)) {
                return true;
            }

            return false;

            // while (e.target.parentNode) {
            //   let el = e.target.parentNode;
            //   return el.matches(parent);
            // }

            // console.log(e.path);
            // return e.path.splice(0, e.path.length-2).map((el, i) => el.matches(parent) ? 1 : 0).join('') > 0
        }
    };

    window.ImageZoom = ImageZoom;
})(window);