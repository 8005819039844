(window => {
  function ProductSwitcher(options) {
    this.options = JSON.parse(JSON.stringify(this.defaults));

    if (typeof options === 'object') {
      Object.values(options).map((option, i) => {
        this.options[Object.keys(options)[i]] = option;
        return true;
      });
    }

    this.init();
  }

  ProductSwitcher.prototype = {
    defaults: {
      gridButton: '.switch__grid',
      gridClasses: 'product-miniature--grid col-6 col-sm-6 col-md-4 col-lg-3',
      listButton: '.switch__list',
      listClasses: 'product-miniature--list col-12',
      products: 'article.product-miniature',
      cookieName: 'productListView',
      useCookie: false
    },
    init() {
      document.addEventListener('click', ({ target }) => {
        switch (true) {
          case target.matches(this.options.gridButton):
            this.toggleClasses(this.options.gridClasses, this.options.listClasses);
            this.setCookie('grid');
            break;
          case target.matches(this.options.listButton):
            this.toggleClasses(this.options.listClasses, this.options.gridClasses);
            this.setCookie('list');
            break;
          default:
            break;
        }
      });
    },
    toggleClasses(add, remove) {
      [...document.querySelectorAll(this.options.products)].map(product => {
        remove.split(' ').map(className => product.classList.remove(className.replace('.', '')));
        add.split(' ').map(className => product.classList.add(className.replace('.', '')));
        return true;
      });
    },
    setCookie(view) {
      if (this.options.useCookie) {
        document.cookie = `${this.options.cookieName}=${view}; expires=0; path=/`;
        return true;
      }

      return false;
    }
  };

  window.ProductSwitcher = ProductSwitcher;
})(window);
