import axios from 'axios';

let global = '';

export default (global = {
    init: () => {
        console.log(`Current page: ${prestashop.page.page_name}`);

        // new PushMenu('.nav', 280);

        global.cartPopup();
        global.searchToggleMobile();
        global.removeFromCartDropdownAjax();
        global.forcePostcodeUppercase();

        // if (prestashop.page.page_name !== 'checkout') {
        //   global.fixedNavBar();
        // }
    },
    fixedNavBar: () => {
        window.onscroll = () => {
            const {offsetHeight, classList: menu} = document.querySelector('.nav');
            const {style, offsetTop} = document.querySelector('.nav--fixed');
            const {pageYOffset} = window;

            switch (true) {
                case pageYOffset <= offsetTop:
                    style.height = 0;
                    menu.remove('nav--sticky');
                    break;
                default:
                    style.height = `${offsetHeight}px`;
                    menu.add('nav--sticky');
            }
        };
    },
    cartPopup: () => {
        document.body.addEventListener('click', e => {
            const {target} = e;
            const overlay = document.querySelector('.modal__overlay');
            const cartModal = document.querySelector('#blockcart-modal');

            switch (true) {
                case target.matches('.continue'):
                    overlay.remove();
                    cartModal.remove();
                    break;
                case target.matches('.modal__overlay'):
                    overlay.remove();
                    cartModal.remove();
                    break;
                default:
                    break;
            }
        });
    },
    searchToggleMobile: () => {

        const searchBoxWrapper = document.querySelector('#search_widget');
        // console.log(mobileToggleButton);

        document.body.addEventListener('click', e => {
            if (e.target.matches('.search-box-toggle')) {
                // console.log(e);
                searchBoxWrapper.classList.toggle('active');
            }
        });

        document.body.addEventListener('click', e => {
            if (e.target.matches('.search-box-overlay')) {
                // console.log(e);
                searchBoxWrapper.classList.remove('active');
            }
        });
    },
    removeFromCartDropdownAjax: () => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('.remove-from-cart')) {
                // Stop the standard link from being actioned
                e.preventDefault();

                const cartRemoveLink = e.target.href;

                // Remove the required product from the cart using axios
                axios.get(cartRemoveLink)
                    .then(function (response) {
                        prestashop.emit('updateCart', {reason: e});
                        // handle success
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        });
    },
    forcePostcodeUppercase: (e) => {
        document.body.addEventListener('keyup', e => {
            if (e.target.matches('#postcode')) {
                var start = e.target.selectionStart;
                var end = e.target.selectionEnd;
                e.target.value = e.target.value.toUpperCase();
                e.target.setSelectionRange(start, end);
            }
        });
    }
});
