import axios from 'axios';

let cart = '';

export default (cart = {
    init: () => {
        cart.updateCartQuanitityAjax();
        cart.removeProductCartAjax();
        cart.removeVoucherCartAjax();
        cart.prefillVoucherCodeButton();
    },

    updateCartQuanitityAjax: () => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('.js-increase-product-quantity')) {
                // Stop the standard link from being actioned
                e.preventDefault();

                const cartQuantityIncreaseLink = e.target.href;

                // Increase the required product quantity in the cart using axios
                axios.get(cartQuantityIncreaseLink)
                    .then(function (response) {
                        prestashop.emit('updateCart', {reason: e});
                        // handle success
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }

            if (e.target.matches('.js-decrease-product-quantity')) {
                // Stop the standard link from being actioned
                e.preventDefault();

                const cartQuantityDecreaseLink = e.target.href;

                // Decrease the required product quantity in the cart using axios
                axios.get(cartQuantityDecreaseLink)
                    .then(function (response) {
                        prestashop.emit('updateCart', {reason: e});
                        // handle success
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        });
    },

    removeProductCartAjax: () => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('.remove-from-cart')) {
                // Stop the standard link from being actioned
                e.preventDefault();

                const cartQuantityIncreaseLink = e.target.href;

                // Remove the required product from the cart using axios
                axios.get(cartQuantityIncreaseLink)
                    .then(function (response) {
                        prestashop.emit('updateCart', {reason: e});
                        // handle success
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        });
    },

    removeVoucherCartAjax: () => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('a[data-link-action="remove-voucher"]')) {
                // Stop the standard link from being actioned
                e.preventDefault();

                const cartQuantityIncreaseLink = e.target.href;

                // Remove the required voucher from the cart using axios
                axios.get(cartQuantityIncreaseLink)
                    .then(function (response) {
                        prestashop.emit('updateCart', {reason: e});
                        // handle success
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        });
    },
    prefillVoucherCodeButton: () => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('a[data-link-action="prefill-voucher-code"]')) {
                // Stop the standard link from being actioned
                e.preventDefault();

                let voucherCodeInputBox = document.querySelector('#discount_name');
                let voucherCodeTextValue = (e.target.dataset.voucherCodeText);

                if (voucherCodeTextValue) {
                    voucherCodeInputBox.value = voucherCodeTextValue;
                }
            }
        });
    }
});
