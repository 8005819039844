let product = '';

export default (product = {
    init: () => {
        product.adjustProductQuantity();
        product.reviewsTabLink();

        // product.imageHover();
        const tabs = new tabJS('.tabs__tab', '.tabs_contents__content');

        // Main product image hover
        const coverImage = new ImageZoom({
            imageType: {
                large: 'product_zoom',
                normal: 'large_default'
            }
        });

        // Product Thumbnail Switch
        document.body.addEventListener('click', product.coverImage.pick);

    },
    adjustProductQuantity: () => {
        const quantityInput = document.querySelector('#quantity_wanted');

        quantityInput.addEventListener('change', e => product.updatedProductQuantity(e));

        document.body.addEventListener('click', e => {
            if (e.target.matches('.adjust')) {
                switch (e.target.dataset.quantity) {
                    case 'up':
                        quantityInput.value = parseInt(quantityInput.value) + 1;
                        product.updatedProductQuantity(e);
                        break;
                    case 'down':
                        if (parseInt(quantityInput.value) === parseInt(quantityInput.min)) {
                            break;
                        } else {
                            quantityInput.value = parseInt(quantityInput.value) - 1;
                            product.updatedProductQuantity(e);
                            break;
                        }
                    default:
                        break;
                }
            }
        });
    },
    updatedProductQuantity: e => {
        prestashop.emit('updateProduct', {
            eventType: 'updatedProductQuantity',
            event: e
        });
    },
    coverImage: {
        pick: e => {
            if (e.target.matches('.product-images__image')) {
                // console.log(e.target.querySelector('img'));
                document.querySelector('.product-cover__image').attributes.src.value = e.target.querySelector('img').attributes.src.value.replace('small_default', 'large_default');
                product.coverImage.select(e.target.querySelector('img').parentElement);
            }
        },

        select: selected => {
            [...document.querySelectorAll('.product-images__image')].map(img => img.classList.remove('product-images__image--active'));
            selected.classList.add('product-images__image--active');
        }
    },
    scroll: (el) => {     // add a smooth scroll to an element
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });

        setTimeout(() => {
            window.scrollBy(0, -150);
        }, 500);
    },
    reviewsTabLink: e => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('a[data-target="product-reviews-tab"]')) {
                e.preventDefault();

                let reviewsTab = document.getElementById(e.target.dataset.target);
                let reviewsTabContent = document.getElementById(reviewsTab.dataset.target);

                let currentActiveTab = document.querySelector('.tabs__tab--active');
                let currentActiveTabContent = document.querySelector('.tabs_contents__content--active');

                let tabContentWrapper = document.querySelector('.tabs__wrapper');

                // Check to see if the current active tab is the one we want to select anyway
                if (currentActiveTab.id == reviewsTab.id) {
                    // If it is, do nothing
                    // console.log('Reviews tab already active');
                } else {
                    // Remove the active class from all existing tabs and tab content if they exist
                    currentActiveTab.classList.remove('tabs__tab--active');
                    currentActiveTabContent.classList.remove('tabs_contents__content--active');

                    // Add the active classes back into the relevant places
                    reviewsTab.classList.add('tabs__tab--active');
                    reviewsTabContent.classList.add('tabs_contents__content--active');


                    product.scroll(tabContentWrapper);

                }
            }
        });
    }
});
