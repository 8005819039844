let category = '';

export default (category = {
    init: () => {
        category.relNextPrev();
        category.toggleFacet();
        category.categoryFilter();

        document.body.addEventListener('change', e => {
            if (e.target.matches('#search_filters input[data-search-url]')) {
                return prestashop.emit('updateFacets', category.parseSearchUrl(e));
            }
        });

        document.body.addEventListener('click', e => {
            if (e.target === document.querySelector('.js-search-filters-clear-all')) {
                e.preventDefault();
                prestashop.emit('updateFacets', e.target.href);
            }
        });

        document.body.addEventListener('click', e => {
            if (e.target.matches('.js-search-link')) {
                e.preventDefault();
                prestashop.emit('updateFacets', e.target.href);
            }
        });

        prestashop.on('updateProductList', data => {
            category.updateProductListDOM(data);
        });
    },
    updateProductListDOM: data => {
        document.querySelector('#search_filters').outerHTML = data.rendered_facets;
        document.querySelector('#js-active-search-filters').outerHTML = data.rendered_active_filters;
        document.querySelector('#js-product-list-top').outerHTML = data.rendered_products_top;
        document.querySelector('#js-product-list').outerHTML = data.rendered_products;
        document.querySelector('#js-product-list-bottom').outerHTML = data.rendered_products_bottom;

        // Remove the Search Filters overlay once the DOM has been updated
        document.querySelector('.search_filters__overlay').classList.remove('search_filters__overlay--visible');
    },
    parseSearchUrl: event => {
        if (event.target.dataset.searchUrl !== undefined) {
            return event.target.dataset.searchUrl;
        }

        if (event.target.parentNode.dataset.searchUrl === undefined) {
            throw new Error('Can not parse search URL');
        }

        return event.target.parentNode.dataset.searchUrl;
    },
    relNextPrev: () => {
        const {head} = document;
        const prevPage = document.querySelector('a.previous') || false;
        const nextPage = document.querySelector('a.next') || false;
        const headPrev = head.querySelector("link[rel='prev']");
        const headNext = head.querySelector("link[rel='next']");
        const prevPageNum = prevPage ? prevPage.href.split('=')[1] : false;
        const nextPageNum = nextPage ? nextPage.href.split('=')[1] : false;
        const linkPrev = !headPrev ? document.createElement('link') : headPrev;
        const linkNext = !headNext ? document.createElement('link') : headNext;
        let currentPage;
        if (document.querySelector('li.current')) {
            currentPage = document.querySelector('li.current').innerText.trim() || false;
        }

        linkPrev.setAttribute('rel', 'prev');
        linkPrev.setAttribute('href', prevPage.href);
        linkNext.setAttribute('rel', 'next');
        linkNext.setAttribute('href', nextPage.href);
        prevPageNum != currentPage ? head.appendChild(linkPrev) : headPrev ? headPrev.parentNode.removeChild(headPrev) : '';
        nextPageNum != currentPage ? head.appendChild(linkNext) : headNext ? headNext.parentNode.removeChild(headNext) : '';
    },
    toggleFacet: () => {
        document.body.addEventListener('click', e => {
            const {target, target: {parentNode}} = e;

            if (e.target.matches('.facet h4')) {
                target.classList.toggle('active');
                if (target.querySelector('i').innerHTML === "keyboard_arrow_down") {
                    target.querySelector('i').innerHTML = "keyboard_arrow_up";
                } else {
                    target.querySelector('i').innerHTML = "keyboard_arrow_down";
                }
                parentNode.querySelector('ul').classList.toggle('active');
            }
        });
    },
    categoryFilter: () => {
        const toggleFilter = ['#category-filter', '.search_filters__overlay'];
        const productListTopSelector = '#js-product-list-top div';
        const {body} = document;

        body.addEventListener('click', e => toggleFilter.map(
            el => !e.target.matches(el) || category.toggleCategoryFilter(),
            )
        );
    },
    toggleCategoryFilter: () => {
        const searchFilter = document.querySelector('#search_filters');
        const filterOverlay = document.querySelector('.search_filters__overlay');
        // const facetList = document.querySelectorAll('.facet__list');
        // const facetTitle = document.querySelectorAll('.facet h4');

        searchFilter.classList.toggle('visible');
        filterOverlay.classList.toggle('search_filters__overlay--visible');
        // [...facetList].map(list => list.classList.remove('facet--visible'));
        // [...facetTitle].map(title => title.classList.remove('expand'));
    }
});
