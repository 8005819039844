(window => {
  function Slider(options) {
    this.options = JSON.parse(JSON.stringify(this.defaults));

    if (typeof options === 'object') {
      Object.values(options).map((option, i) => {
        this.options[Object.keys(options)[i]] = option;
        return true;
      });
    }

    this.init();
  }

  Slider.prototype = {
    defaults: { container: '.slider', slide: '.slider__slide', active: '.slider__slide--active', next: '.slider__slide--next', previous: '.slider__slide--previous', timer: 3000, current: 0 },
    init() {
      const selector = c => c.replace(/(.|#)/, '');
      const slider = document.querySelector(this.options.container);
      let slides = [...slider.querySelectorAll(this.options.slide)];
      let slideLength = slides.length;

      slider.style.opacity = 1;

      if (slideLength < 4 && slideLength > 1) {
        slider.insertAdjacentHTML('beforeend', slider.innerHTML);
        slides = [...slider.querySelectorAll(this.options.slide)];
        slideLength = slides.length;
      }

      if (slideLength >= 4) {
        slides[0].classList.add(selector(this.options.active));
        slides[1].classList.add(selector(this.options.next));
        slides[slideLength - 1].classList.add(selector(this.options.previous));

        setInterval(() => {
          this.options.current = this._slider(slides, this);
        }, this.options.timer);
      }
    },
    _slider(slides, { options }) {
      const selector = c => c.replace(/(.|#)/, '');
      const length = slides.length - 1;
      const { active, previous, next } = options;
      let { current } = options;

      slides[current].classList.remove(selector(active));
      slides[current].classList.add(selector(previous));

      switch (true) {
        case current === length:
          current = 0;
          this._slideChange(slides[current], slides[length - 1], slides[current + 1], options);
          break;

        case current === length - 1:
          current = current + 1;
          this._slideChange(slides[current], slides[length - 2], slides[0], options);
          break;

        case current === 0:
          current = current + 1;
          this._slideChange(slides[current], slides[length], slides[current + 1], options);
          break;

        default:
          current = current + 1;
          this._slideChange(slides[current], slides[current - 2], slides[current + 1], options);
          break;
      }

      return current;
    },
    _slideChange(elActive, elPrev, elNext, { next, active, previous }) {
      elActive.classList.remove(this._getSelector(next));
      elActive.classList.add(this._getSelector(active));
      elPrev.classList.remove(this._getSelector(previous));
      elNext.classList.add(this._getSelector(next));
    },
    _getSelector(c) {
      return c.replace(/(.|#)/, '');
    }
  };

  window.Slider = Slider;
})(window);
