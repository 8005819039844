let checkout = '';

export default (checkout = {
    init: () => {
        checkout.prefillVoucherCodeButton()
    },
    prefillVoucherCodeButton: () => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('a[data-link-action="prefill-voucher-code"]')) {
                // Stop the standard link from being actioned
                e.preventDefault();

                let voucherCodeInputBox = document.querySelector('#discount_name');
                let voucherCodeTextValue = (e.target.dataset.voucherCodeText);

                if (voucherCodeTextValue) {
                    voucherCodeInputBox.value = voucherCodeTextValue;
                }
            }
        });
    }
});
