(window => {
  function tabJS(tab, content, options) {
    this.options = JSON.parse(JSON.stringify(this.defaults));

    if (typeof options === 'object') {
      Object.values(options).map((option, i) => {
        this.options[Object.keys(options)[i]] = option;
        return true;
      });
    }

    if (tab !== '' && content !== '') {
      this.tabSelector = tab;
      this.contentSelector = content;
      this.tabClass = this.tabSelector.replace(/(.|#)/, '');
      this.contentClass = this.contentSelector.replace(/(.|#)/, '');
      this.tabs = [...document.querySelectorAll(this.tabSelector)];
      this.contents = [...document.querySelectorAll(this.contentSelector)];

      this.init();
    }
  }

  tabJS.prototype = {
    defaults: {
      active: '--active'
    },
    init() {
      // if (this.tabs.filter(el => el.classList.contains(`${this.tabSelector}${this.options.active}`).length === 0)) {
      //   this._removeActive();
      //   this._addActive(this.tabs[0], this.tabClass);
      //   this._addActive(document.getElementById(this.tabs[0].dataset.target), this.contentClass);
      // }

      this._control();
    },
    _control() {
      document.body.addEventListener('click', e => {
        if (e.target.matches(this.tabSelector)) {
          this._removeActive();
          this._addActive(e.target, this.tabClass);
          this._addActive(document.getElementById(e.target.dataset.target), this.contentClass);
        }
      });
    },
    _removeActive() {
      this.tabs.filter(el => el.classList.contains(`${this.tabClass}${this.options.active}`)).map(content => content.classList.remove(`${this.tabClass}${this.options.active}`));
      this.contents.filter(el => el.classList.contains(`${this.contentClass}${this.options.active}`)).map(content => content.classList.remove(`${this.contentClass}${this.options.active}`));
    },
    _addActive(target, selector) {
      target.classList.add(`${selector}${this.options.active}`);
    }
  };

  window.tabJS = tabJS;
})(window);
